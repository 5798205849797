import React, { useState } from "react";
import "./Style/profiledetail.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Feedback } from "./Feedback";
import SideBar from "../Components/SideBar/SideBar";
import TextLinkExample from "../Components/NavBar/NavBar";
import { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";
import { useQualitativeQuery } from "../../redux/API/portfolioApi";
import LogoutCard from "../Components/Card/LogoutCard";
import { Link } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa6";
import Footer from "../Components/Footer/Footer";
import ReactPaginate from "react-paginate";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Qualitative = () => {
  const { isSiderbaropen } = useContext(SidebarContext);
  const [showLogout, setShowLogout] = useState(false);
  const { data: qualitativeAnalysis, isLoading } = useQualitativeQuery();
  const [currentPage, setCurrentPage] = useState(0);

  //Pagination
  const itemsPerPage = 20;

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const offset = currentPage * itemsPerPage;
  const currentItems = qualitativeAnalysis?.data?.slice(
    offset,
    offset + itemsPerPage
  );

  return (
    <>
      <div className="App">
        <SideBar />
        <div className={!isSiderbaropen ? "pages" : "pages-change"}>
          <TextLinkExample setShowLogout={setShowLogout} />
          <div className="ProfileDetail" style={{ minHeight: "100vh" }}>
            <div
              className="portfolio-detail-table my-5 col-11 flex-column align-items-lg-start  "
              style={{ margin: "auto" }}
            >
              <p className="mb-0">
                {qualitativeAnalysis?.data?.length} Record(s) Found
              </p>
              <TableContainer className="mt-3" component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="qualitative-table-head">
                        Stocks
                      </StyledTableCell>
                      {qualitativeAnalysis?.data?.[0]?.qualitiveanalyses
                        ?.length > 0 ? (
                        qualitativeAnalysis?.data?.[0]?.qualitiveanalyses?.map(
                          (j, index) => (
                            <StyledTableCell
                              key={`${index}`}
                              className="qualitative-table-head"
                              sx={{
                                width: "400px",
                                minWidth: "400px",
                                maxWidth: "400px",
                              }}
                            >
                              {j.title}
                            </StyledTableCell>
                          )
                        )
                      ) : (
                        <StyledTableCell
                          className="qualitative-table-head"
                          sx={{
                            width: "400px",
                            minWidth: "400px",
                            maxWidth: "400px",
                          }}
                        >
                          <strong> Title </strong>
                        </StyledTableCell>
                      )}

                      <StyledTableCell className="qualitative-table-head">
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell className="text-center" colSpan={3}>
                          Loading...
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {currentItems?.map((res, rowIndex) => (
                        <StyledTableRow key={rowIndex}>
                          <StyledTableCell
                            className="qualitative-table text-capitalize"
                            sx={{ textAlign: "start !important" }}
                          >
                            {res?.stocklist.length > 0 ? (
                              res?.stocklist.map((i, stockIndex) => (
                                <strong key={stockIndex}>{i?.name}</strong>
                              ))
                            ) : (
                              <strong> - </strong>
                            )}
                          </StyledTableCell>

                          {res?.qualitiveanalyses?.length > 0
                            ? res?.qualitiveanalyses?.map(
                                (analysis, qualIndex) => (
                                  <StyledTableCell
                                    key={`qual-cell-${qualIndex}`}
                                    className="qualitative-table"
                                    sx={{ textAlign: "start !important" }}
                                  >
                                    {analysis?.description}
                                  </StyledTableCell>
                                )
                              )
                            : [
                                ...Array(
                                  qualitativeAnalysis?.data?.[0]
                                    ?.qualitiveanalyses?.length || 1
                                ),
                              ].map((_, i) => (
                                <StyledTableCell
                                  key={`dash-${i}`}
                                  className="qualitative-table"
                                >
                                  -
                                </StyledTableCell>
                              ))}
                          <StyledTableCell className="qualitative-table view-btn">
                            {res?.stocklist.length > 0 ? (
                              res?.stocklist.map((i) => (
                                <Link to={i?.stockpdf} target="_blank">
                                  <FaFilePdf />
                                </Link>
                              ))
                            ) : (
                              <strong> - </strong>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  )}

                    {!isLoading && (qualitativeAnalysis?.data?.length === 0) && (
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell className="text-center" colSpan={3}>No Record found</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        )}
                </Table>
              </TableContainer>
              {!isLoading && (qualitativeAnalysis?.data?.length > 0) && (
              <ReactPaginate
                previousLabel={"← Prev"}
                nextLabel={"Next →"}
                pageCount={Math.ceil(
                  qualitativeAnalysis?.data?.length / itemsPerPage
                )}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                className="mx-auto d-flex pagination mt-3"
              />
              )}
            </div>
          </div>
          <Footer />
        </div>
        <Feedback />
      </div>
      {showLogout && <LogoutCard setShowLogout={setShowLogout} />}
    </>
  );
};

export default Qualitative;
