import React, { useEffect } from "react";
import Navbar from "../components/partial/Navbar";
import Footer from "../components/partial/Footer";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Blog from "../components/Home/Blog";
import check from '../images/check-mark-svgrepo-com.svg'
import { Helmet } from "react-helmet-async";
const AlternateInvestement = () => {
  useEffect(() => {
    AOS.init({
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false,
    });
  }, []);

  return (
    <>
    <Helmet>
        <title>SEBI Registered Equity Investment Advisor - Brighter Mind</title>
       
      </Helmet>
      <Navbar />
      <section className="portfolio-section alternate-investement">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 text-white">
              <div className="mt-5">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link> <MdKeyboardArrowRight />
                    </li>
                    <li
                      className="breadcrumb-item text-white "
                      aria-current="page"
                    >
                      Alternate Investment Fund
                    </li>
                  </ol>
                </nav>
              </div>
              <div data-aos="fade-up">
             <h2 className="fw-bold mt-5 pt-lg-5 text-uppercase">Alternate Investment Fund</h2>
              
             </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 container">
        <h2 className="heading text-center ">
          Delivering Value with Alternate Investment Fund
        </h2>
        <div className="row mt-3">
          <div className="col-12">
            <p className=" text-dark-emphasis ">
            We understand that our clients are not just looking for ordinary returns; they seek higher alpha, resilience and smart growth. This is why we have launched our AIF services, designed to harness unique investment opportunities in equity market. Brighter Mind Alternate Investment Fund represents our holistic investment philosophy deeply rooted in research, innovation, and strategic insight.
            </p>
            <p className=" text-dark-emphasis ">We have registered our AIF with SEBI in 2024 to offer alternate investment fund services to investors in India and abroad with only objective of delivering alpha returns along with safety of capital.</p>
            <p className=" text-dark-emphasis ">
            Our AIF fills the investment gap with deep market knowledge, robust analytical and research frameworks by identifying opportunities that are typically inaccessible to the average investor. Hence we offer unparalleled opportunities to our investors that align with our core values: transparency, high growth, and client-centricity.
            </p>
          </div>
        </div>
      </section>
      <section className="mt-3 py-5 bg-body-secondary">
        <div className="container">
          <h2 className="heading text-center " >
            Brighter Mind Alternate Investment Fund Benefits
          </h2>
          <div className="row mt-3">
            <di className="col-12">
              <div className="row gy-4 justify-content-center">
                <div className="col-lg-4 col-md-4 col-6  fw-medium">
                 <div className="d-flex align-items-center benifit-card">
                 <div className="check-box">
                 <img src={check} alt="Check" width={24} /> 
                 </div>
                 <p className="mb-0 fw-medium">Focus on Long Term Wealth</p>
                 </div>
                </div>
                <div className="col-lg-4 col-md-4 col-6  fw-medium">
                <div className="d-flex  align-items-center benifit-card">
               <div className="check-box">
               <img src={check} alt="Check" width={24} />
               </div>
                <p className="mb-0  fw-medium">Higher Alpha due to pooled fund</p>
                </div>
                </div>
                <div className="col-lg-4 col-md-4 col-6  fw-medium">
                <div className="d-flex  align-items-center benifit-card">
               <div className="check-box">
               <img src={check} alt="Check" width={24} />
               </div>
                <p className="mb-0  fw-medium">Enhanced Portfolio Diversification</p>
                </div>
                 
                </div>
                <div className="col-lg-4 col-md-4 col-6  fw-medium">
                <div className="d-flex  align-items-center benifit-card">
              <div className="check-box">
              <img src={check} alt="Check" width={24} /> 
              </div>
                <p className="mb-0  fw-medium">Active Portfolio Management</p>
                </div>
                  
                </div>
                <div className="col-lg-4 col-md-4 col-6 fw-medium">
               <div className="d-flex  align-items-center benifit-card">
              <div className="check-box">
              <img src={check} alt="Check" width={24} />
              </div>
               <p className="mb-0  fw-medium">Transparency</p>
               </div>
                 
                </div>
                <div className="col-lg-4 col-md-4 col-6 fw-medium">
               <div className="d-flex  align-items-center benifit-card">
              <div className="check-box">
              <img src={check} alt="Check" width={24} />
              </div>
                <p className="mb-0  fw-medium">Client Centric Approach</p>
                 
               </div>
                </div>
              </div>
            </di> 
          </div>
        </div>
      </section>
      <section className="mt-5 container overflow-hidden">
        <h2 className="heading text-center ">Brighter Mind Secret Advantage Fund</h2>
        <div className="row gy-3 mt-3">
          <div className="col-md-5" data-aos="fade-right">
            <img
              src="https://plus.unsplash.com/premium_photo-1681487769650-a0c3fbaed85a?q=80&w=1910&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
              className="w-100 h-100 object-fit-cover"
            />
          </div>
          <div className="col-md-7">
            <h6>BM Opportunistic Value Fund – I Scheme</h6>
            <p className=" text-dark-emphasis " data-aos="fade-left">
            In November 2024, we launched our Opportunistic Value Fund – I with the corpus of $6Million with the vision of attaining a total AUM of $100 Million in next 5 years. The Opportunistic Value Fund is designed for investors who seek a balanced approach to growth and stability. Our investment strategy focuses on selecting companies that demonstrate strong fundamentals, sustainable competitive advantages, and consistent performance and meets our stringent stock research framework.
            </p>
            <p className=" text-dark-emphasis " data-aos="fade-left">
            When you invest in the Brighter Mind Opportunistic Value Fund - I, you are partnering with a team that is deeply committed to your financial success. With rigorous research methodology our sole focus is on delivering value to our clients. Our transparent, client-centric approach ensures that you are always informed and confident in your investment choices. So unlock the potential of your investments with the Opportunistic Value Fund.
            </p>
          </div>
        </div>
      </section>
      <section className="mt-5 py-5 bg-body-secondary">
        <div className="container">
          <h2 className="heading text-center mb-4">Investment Philosophy</h2>
          <div className="row gy-5 justify-content-center">
            <div className="col-lg-4">
            <div class="investement-card shadow-md">
               <div className="card-title-container">
               1
               </div>
                <p class="small-desc mb-0 text-start fw-medium">
                Strategic Analysis of Company
                </p>
              </div>
            </div>
            <div className="col-lg-4">
            <div class="investement-card investement-card-2 shadow-md">
                <div className="card-title-container ">
                2
                </div>
                <p class="small-desc mb-0 text-start fw-medium">
                Identification of Economic Moat
                </p>
              </div>
            </div>
            <div className="col-lg-4">
            <div class="investement-card shadow-md investement-card-3">
               <div className="card-title-container ">
              3
               </div>
                <p class="small-desc mb-0 text-start fw-medium">
                Consistent Performance of Business
                </p>
              </div>
            </div>
            <div className="col-lg-4">
            <div class="investement-card investement-card-4 shadow-md">
               <div className=" card-title-container ">
               4
               </div>
                <p class="small-desc mb-0 text-start fw-medium">
                Risk Management with Diversification
                </p>
              </div>
            </div>
            <div className="col-lg-4">
            <div class="investement-card investement-card-5 shadow-md">
               <div className=" card-title-container ">
               5
               </div>
                <p class="small-desc mb-0 text-start fw-medium">
                Higher Margin of Safety
                </p>
              </div>
            </div>
            <div className="col-lg-4">
            <div class="investement-card investement-card-6 shadow-md">
               <div className="card-title-container ">
              6
               </div>
                <p class="small-desc mb-0  text-start fw-medium ">
                Long Term Investment Horizon
                </p>
              </div>
            </div>
          
          </div>
        </div>
      </section>
      <section className="mt-5 container">
        <h2 className="heading text-center ">FAQ</h2>
        <div className="row gy-3 mt-3">
            <div className="col-12">
            <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      1.	What type of AIF is Brighter Mind’s Alternate Investment Fund?
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Brighter Mind AIF is a long-only category III AIF, with exposure in only listed Indian equities.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      2.	What is the minimum investment amount for AIF?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">The minimum investment amount is Rs. 1 Crore for availing the Brighter Mind AIF services.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      3.	What is tax computation in AIF?
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Your wealth created from the scheme is exempted from all the taxation. This is because all the tax computation happens at fund level.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
      4.	Is there any lock-in in Brighter Mind AIF?
      </button>
    </h2>
    <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Yes, there is a lock-in period of 1 year in Brighter Mind AIF. </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
      5.	How are AIFs regulated in India?
      </button>
    </h2>
    <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">AIFs in India are regulated by the Securities and Exchange Board of India (SEBI) under the SEBI (Alternative Investment Funds) Regulations, 2012.</div>
    </div>
  </div>
</div>
            </div>
        </div>
        </section>
      <Blog/>
      <Footer />
    </>
  );
};

export default AlternateInvestement;
